/*Обнуление*/
*,
*:before,
*:after {
  padding: 0;
  margin: 0;
  border: 0;
  box-sizing: border-box;
}

.wrapper {
  height: 100%;
  padding: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
}

@media (max-width: 767px) {
  .wrapper {
    padding: 50px 10px;
  }
}

.slick-slider {
  min-width: 0;
}

.slick-list {
  overflow: hidden;
}

.slick-track {
  display: flex;
  align-items: flex-start;
}

.slider {
  position: relative;
  padding: 0px 60px;
}

@media (max-width: 767px) {
  .slider {
    padding: 0px 40px;
  }
}

.slider .slick-arrow {
  position: absolute;
  top: 50%;
  margin: -20px 0px 0px 0px;
  z-index: 10;
  font-size: 0;
  width: 30px;
  height: 30px;
}
.slider .slick-arrow.slick-prev {
  left: 0;
  background: url("https://img.icons8.com/ios/50/000000/long-arrow-left.png") 0
    0 / 100% no-repeat;
}
.slider .slick-arrow.slick-next {
  right: 0;
  background: url("https://img.icons8.com/ios/50/000000/long-arrow-right.png") 0 0 / 100% no-repeat;
}
.slider .slick-arrow.slick-disabled {
  opacity: 0.2;
}

.slider__item {
  padding: 0px 15px;
}
.slider__item img {
  max-width: 100%;
}
