h2 {
    font-weight: 600;
    padding: 8px 0;
}
p{
   
    padding: 8px 0;
}

.linfo{    
    list-style: disc url("../../../Assets/Pictures/ominogirato.png");
  
    margin-left: 25px;
    background-position: center center;
    background-size: 15px 15px;
}